import Axios from "axios";

export const omenaAxios = Axios.create({
    baseURL: `/`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Authorization, Lang",
      "Access-Control-Allow-Methods": "GET,POST,OPTIONS,PUT,DELETE,PATCH",
    },
  });

  export const omenaAxiosPrivate = Axios.create({
    baseURL: `/`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Authorization, Lang",
      "Access-Control-Allow-Methods": "GET,POST,OPTIONS,PUT,DELETE,PATCH",
    },
    withCredentials: true
  });

  // omenaAxiosPrivate.interceptors.request.use(
  //   (config: any) => {
  //     const token = window.localStorage.getItem("member_token");
  //     if (config.headers === undefined) {
  //       config.headers = {};
  //     }
  //     if (!config?.headers["Authorization"]) {
  //       config.headers["Authorization"] = `Bearer ${token}`;
  //     }
  //     return config;
  //   },
  //   (error: any) => {
  //     return Promise.reject(error);
  //   }
  // );