import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import "./styles/globals.css";
import { getConfig } from './services/api/authApi';
import { setGlobalHotelConfig } from './services/utils/global';
const App = lazy(() => import("./App"))

const root = document.getElementById('root') as HTMLElement;

const renderApp = async() => {
  const getConf:any = await getConfig();
  if(getConf.error) {
    console.log("Error getting configuration")
  } else {
    setGlobalHotelConfig(getConf);
  }
  ReactDOM.render(
    <Suspense fallback={<div className='full-center'>
      <div className="init-spinner"></div>
    </div>}>
      <App />
    </Suspense>
    , root
  );
}

renderApp();