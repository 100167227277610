import { hotelOffer } from "../api/hotelsApi";
import { PriceCatalog } from "../api/pricesApi";
import { productType } from "../api/reservationApi";
import { HotelWebConfig } from "../store/authStore";
import { GlobalPricing } from "../store/roomFilterStore";
import { DisabledList, OverrideList, RoomDateRange } from "../store/roomStore";

let GlobalHotelPrice:Map<string, GlobalPricing> = new Map<string, GlobalPricing>();
let GlobalHotelBookingStats:Map<number, number> = new Map<number, number>();

let GlobalHotelOffer:hotelOffer | null = null;
const setGlobalHotelOffer = (data:hotelOffer) => {
  GlobalHotelOffer = data;
}
let GlobalMappedProduct:productType[] = [];
const setGlobalMappedProduct = (data:productType[]) => {
  GlobalMappedProduct = data;
}
let GlobalRoomDateRange:RoomDateRange[] = [];
const setGlobalRoomDateRange = (data:RoomDateRange[]) => {
  GlobalRoomDateRange = data;
}
let GlobalOverrideList:OverrideList[] = [];
const setGlobalOverrideList = (data:OverrideList[]) => {
  GlobalOverrideList = data;
}
let GlobalDisabledList:productType[] = [];
const setGlobalDisabledList = (data:productType[]) => {
  GlobalDisabledList = data;
}

let requestingPreview:boolean = false;
const setRequestingPreview = (state:boolean) => {
  requestingPreview = state;
}

let requestRecalculateGroupOverride: boolean = false;
const setRequestRecalculateGroupOverride = (state:boolean) => {
  requestRecalculateGroupOverride = state;
}

let roomChange: boolean = false;
const setRoomChange = (state:boolean) => {
  roomChange = state;
}
let customizeDateChangePending: boolean = false;
const setCustomizeDateChangePending = (state:boolean) => {
  customizeDateChangePending = state;
}

// Manage booking
let changedManageDate: boolean = false;
const setChangedManageDate = (state:boolean) => {
  changedManageDate = state;
}
let firstOverrideSetup: boolean = false;
const setFirstOverrideSetup = (state:boolean) => {
  firstOverrideSetup = state;
}

// Misc
let GlobalHotelConfig: HotelWebConfig = {} as HotelWebConfig;
const setGlobalHotelConfig = (state:HotelWebConfig) => {
  GlobalHotelConfig = state;
}

export {
  GlobalHotelPrice,
  GlobalHotelBookingStats,
  GlobalHotelOffer,
  setGlobalHotelOffer,
  GlobalMappedProduct,
  setGlobalMappedProduct,
  GlobalRoomDateRange,
  setGlobalRoomDateRange,
  GlobalOverrideList,
  setGlobalOverrideList,
  GlobalDisabledList,
  setGlobalDisabledList,
  requestingPreview,
  setRequestingPreview,
  requestRecalculateGroupOverride,
  setRequestRecalculateGroupOverride,
  customizeDateChangePending,
  setCustomizeDateChangePending,
  roomChange,
  setRoomChange,
  changedManageDate,
  setChangedManageDate,
  firstOverrideSetup,
  setFirstOverrideSetup,
  GlobalHotelConfig,
  setGlobalHotelConfig
};