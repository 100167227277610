import i18n from "../localization/i18n";
import { HotelWebConfig } from "../store/authStore";
import { omenaAxios, omenaAxiosPrivate } from "./axios";

const LOGIN_URL = "/api/membership/login";
const LOGIN_ADMIN_URL = "/api/user/login";
const FORGOT_PASSWORD_URL = "/api/membership/forgotPassword";
const RESET_PASSWORD_URL = "/api/membership/confirmForgotPassword";
const RESET_SAP_PASSWORD_URL = "/api/s_app/confirmForgotPassword";
const LOGOUT_URL = "/api/user/logout";

export type ProfileType = {
  address: string;
  city: string;
  created: string;
  dateOfBirth: string;
  emailReal: string;
  emailVirtual: string;
  firstName: string;
  isoCountryCode: string;
  lastName: string;
  marketingPermission: boolean;
  memberId: number;
  membershipNumber: string;
  mobile: string;
  nationality: string;
  newMemberAndWaitingToChangePass: boolean;
  passportNumber: string;
  postalCode: string;
  ssn: string;
  status: string;
  uuid: string;
  //Properties are just used for payment info. User profile does not have this.
  organizationName?: string;
  organizationRef?: string;
  organizationVat?: string;
  purposeOfVisit?: string;
};

export type CustomerSearchType = {
  companyName: string,
  companyReference: string,
  companyYTunnus: string,
  email: string,
  firstName: string,
  lastName: string,
  mobile: string,
  ssn: string,
  postalCode: string,
  passportNumber: string,
  nationality: string,
  city: string,
  address: string
}

export const signInApi = async (email: string, password: string) => {
  let err = null;
  let data = null;
  try {
    const res = await omenaAxios.post(
      LOGIN_URL,
      JSON.stringify({ email, password })
    );
    data = res;
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const AdminLogOutApi = async () => {
  let err = null;
  let data = null;
  try {
    const token:string | null = window.localStorage.getItem("jwt");
    if(token) {
      const res = await omenaAxios.post(
        LOGOUT_URL,
        token
      );
      data = res;
    }
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const GetEnvironment = async () => {
  let err = null;
  let data = null;
  try {
    const res = await omenaAxios.get("/api/env");
    data = res;
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const AdminSignInApi = async (username:string, refreshtoken: string) => {
  let err = null;
  let data = null;
  try {
    const res = await omenaAxios.post(
      LOGIN_ADMIN_URL, {
        username: username,
        refreshtoken: refreshtoken
      }
    );
    data = res;
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const sendEmailLostPassword = async (email: string, lang: string) => {
  let err = null;
  let data = null;
  try {
    const res = await omenaAxios.post(
      FORGOT_PASSWORD_URL,
      JSON.stringify({ email, lang })
    );
    data = res;
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const getProfileApi = async (uuid: string) => {
  let err = null;
  let data = {} as ProfileType;
  try {
    let res = await omenaAxiosPrivate.get(
      `/api/membership/secure/${uuid}/profile`
    );
    data = res.data;
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const searchCustomerApi = async (adminAccess:boolean, query:string, offset:number) => {
  const payload = `query=${query}&offset=${offset}&limit=10`
  try {
    const ret = await omenaAxiosPrivate.get<any>(
      `/api/management/secure/contact?${payload}`,
      {
        headers: {
          "Access-Control-Allow-Headers": "Authorization, Lang",
          "Access-Control-Allow-Methods": "GET",
        },
      }
    );
    return ret.data;
  } catch (e) {
    return {error: e} as any;
  }
}

export const getConfig = async () => {
  try {
    const hotelConfig = await omenaAxios.get<any>(`/config/hotel-config.json`);
    const featureConfig = await omenaAxios.get<any>(`/config/feature-config.json`);
    let thnConfig:any = null;
    if(featureConfig.data.useTheHotelNetworkTracking) {
      thnConfig = await omenaAxios.get<any>(`/param/thn-id.json`);
    }
    if(hotelConfig && featureConfig) {
      let fullConfig:HotelWebConfig = {
        hotel: hotelConfig.data,
        feature: featureConfig.data
      }
      if(thnConfig) {
        fullConfig.feature.theHotelNetworkTracking = thnConfig.data;
      }
      return fullConfig;
    }
  } catch (e) {
    return {error: e} as any;
  }
}

export const saveProfileApi = async (token:string, request:ProfileType) => {
  let err = null;
  let data = null;
  try {
    let res = await omenaAxiosPrivate.post(
      `/api/membership/secure/${request.uuid}/profile/${i18n.language}`,
      request,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    data = res.data;
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const confirmForgotPassword = async (body: any, resetType:string) => {
  let err = null;
  let data = {} as ProfileType;
  try {
    let res = await omenaAxios.post(
      `${resetType === "membership" ? RESET_PASSWORD_URL : RESET_SAP_PASSWORD_URL}`,
      body
    );
    data = res.data;
  } catch (error) {
    err = error;
  }
  return { data, err };
};

export const requestDeleteAccount = async (token:string, memberUuid:string) => {
  let err = null;
  let data = null;
  try {
    const res = await omenaAxiosPrivate.delete(
      `/api/membership/secure/${memberUuid}/account`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    data = res;
  } catch (error) {
    err = error;
  }
  return { data, err };
};